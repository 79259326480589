
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Cfg } from './../../_config/gral.config';

@Injectable({
  providedIn: 'root'
})

export class MensajeService {
    public url: string;
    public token: string;
  constructor(private http: HttpClient) { 
    this.url = Cfg.BackendUrl;
    this.token= null;

  }

  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  


  mensaje_list(): Observable<any> {
    return this.http.get(this.url + '/mensaje')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  mensaje_uno(id): Observable<any> {
    return this.http.get(this.url + '/mensaje' + id)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  

  mensaje_nuevo(data): Observable<any> {
    let mkdata=JSON.stringify(data)
    return this.http.post(this.url + '/mensaje', mkdata, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  

  mensaje_actualizar(id, data): Observable<any> {
    let mkdata=JSON.stringify(data)
    return this.http.put(this.url + + '/mensaje/' + id, mkdata, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  mensaje_borrar(id){
    return this.http.delete<any>(this.url + '/mensaje/' + id, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Error handling 
  handleError(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     window.alert(errorMessage);
     return throwError(errorMessage);
  }
  
}



import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Cfg } from './../../_config/gral.config';

@Injectable({
  providedIn: 'root'
})

export class Com_prodService {
    public url: string;
    public token: string;
    public headers: any;
    public identity: any;
    public paramsList: any;
    
    //public params: any;
  constructor(private http: HttpClient) { 
    this.url = Cfg.BackendUrl;
    this.token=localStorage.getItem('token');
  }
  
  
  com_prod_list(data): Observable<any> {

    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');

    let mkparams = new HttpParams();
    mkparams = mkparams.append('empresa',  data.empresa);
    mkparams = mkparams.append('sucursal', data.sucursal);
    mkparams = mkparams.append('idioma',   data.idioma);
    mkparams = mkparams.append('usuario',  data.usuario);
    mkparams = mkparams.append('ejercicio',data.ejercicio);
    mkparams = mkparams.append('periodo',  data.periodo);
    mkparams = mkparams.append('producto',  data.producto);

    // console.log('mkheaders',mkheaders);
    // console.log('mkparams',mkparams);

    return this.http.get(this.url + '/comprod', { headers:mkheaders,params: mkparams } )
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }


  com_prod_list_fijo(data): Observable<any> {

    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');

    let mkparams = new HttpParams();
    mkparams = mkparams.append('empresa',  data.empresa);
    mkparams = mkparams.append('sucursal', data.sucursal);
    mkparams = mkparams.append('idioma',   data.idioma);
    mkparams = mkparams.append('usuario',  data.usuario);
    mkparams = mkparams.append('ejercicio',data.ejercicio);
    mkparams = mkparams.append('periodo',  data.periodo);
    return this.http.get(this.url + '/comprodfijo', {headers:mkheaders,params: mkparams} )
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  com_prod_list_var(data): Observable<any> {

    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');

    let mkparams = new HttpParams();
    mkparams = mkparams.append('empresa',  data.empresa);
    mkparams = mkparams.append('sucursal', data.sucursal);
    mkparams = mkparams.append('idioma',   data.idioma);
    mkparams = mkparams.append('usuario',  data.usuario);
    mkparams = mkparams.append('ejercicio',data.ejercicio);
    mkparams = mkparams.append('periodo',  data.periodo);
    return this.http.get(this.url + '/comprodvar', {headers:mkheaders,params: mkparams} )
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  com_prod_uno(id, data): Observable<any> {
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexico');

    let mkparams = new HttpParams();
    mkparams = mkparams.append('idioma',   data.idioma);


    return this.http.get(this.url + '/comprod/' + id, {headers:mkheaders,params: mkparams})
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  


  com_prod_nuevo(data): Observable<any> {
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexico');
    let mkdata=JSON.stringify(data)
    return this.http.post(this.url + '/comprod', mkdata, {headers:mkheaders})
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  

  com_prod_actualizar(id, data): Observable<any> {
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');
    let mkdata=JSON.stringify(data)
    return this.http.put(this.url +  '/comprod/' + id, mkdata, {headers:mkheaders})
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  com_prod_borrar(id,data){
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');

    let mkparams = new HttpParams();
    mkparams = mkparams.append('idioma',   data.idioma);

    return this.http.delete<any>(this.url + '/comprod/' + id, {headers:mkheaders,params: mkparams})
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  // Error handling 
  handleError(error) {
     return throwError(error);
  }
  
}



import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Cfg } from './../../_config/gral.config';

@Injectable({
  providedIn: 'root'
})

export class PrecomisionService {
    public url: string;
    public token: string;
    public headers: any;
    public identity: any;
    public paramsList: any;
    
    //public params: any;
  constructor(private http: HttpClient) { 
    this.url = Cfg.BackendUrl;
    this.token=localStorage.getItem('token');
  }
  
  
  precomision_list(data): Observable<any> {
    
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');

    let mkparams = new HttpParams();
    mkparams = mkparams.append('empresa',  data.empresa);
    mkparams = mkparams.append('sucursal', data.sucursal);
    mkparams = mkparams.append('idioma',   data.idioma);
    mkparams = mkparams.append('usuario',  data.usuario);
    mkparams = mkparams.append('ejercicio',data.ejercicio);
    mkparams = mkparams.append('periodo',  data.periodo);

    // console.log('mkheaders',mkheaders);
    // console.log('mkparams',mkparams);

    return this.http.get(this.url + '/precomision', {headers:mkheaders,params: mkparams} )
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  precomision_uno(id): Observable<any> {
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');
    return this.http.get(this.url + '/precomision/' + id, {headers:mkheaders})
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  

  precomision_nuevo(data): Observable<any> {
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');
    let mkdata=JSON.stringify(data)
    return this.http.post(this.url + '/precomision', mkdata, {headers:mkheaders})
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  

  precomision_actualizar(id, data): Observable<any> {
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');
    let mkdata=JSON.stringify(data)
    return this.http.put(this.url +  '/precomision/' + id, mkdata, {headers:mkheaders})
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }



  precomision_borrar(id){
    let mkheaders = new HttpHeaders();
    mkheaders = mkheaders.set('Content-Type','application/json');
    mkheaders = mkheaders.set('authorization',this.token);
    mkheaders = mkheaders.set('mksoftwaredev','Mexcico');
    return this.http.delete<any>(this.url + '/precomision/' + id, {headers:mkheaders})
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  // Error handling 
  handleError(error) {
     return throwError(error);
  }
  
}


'use strict'
//import * as $ from "jquery";


declare var toastr: any;
declare var jQuery:any;
declare var $:any;
declare var Clipboard:any;

export class HMTLfuctions {
    static getEstatusClass(iestatus){
        if (iestatus == "ALTA"){
            return 'label theme-bg text-white f-12';
        } else if (iestatus == "BAJA") {
            return 'label theme-bg2 text-white f-12';                 
        } else {
            return 'label theme-bg text-white f-12';
        }
    } 
}